import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Stack,
  Button,
} from '@chakra-ui/react';

import firebase, {
  signInWithFacebook,
  signInWithGoogle,
} from './services/firebase';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

function Home() {
  const auth = getAuth();

  const [token, setToken] = useState('');

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log(auth.currentUser.accessToken);
        setToken(auth.currentUser.accessToken);
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
  }, []);

  return (
    <Stack spacing="0px" pb={{ base: '10%', md: 'initial' }}>
      {/* <Header /> */}
      <Box>{/* <Content /> */}</Box>
      <Stack px="10px">
        <Button
          colorScheme="orange"
          onClick={() => {
            signInWithGoogle();
          }}
        >
          Signin with Google
        </Button>
        <Button
          colorScheme="blue"
          onClick={() => {
            signInWithFacebook();
          }}
        >
          Signin with Facebook
        </Button>
        <Button
          onClick={() => {
            signOut(auth)
              .then(() => {
                // Sign-out successful.
                alert('Logout success');
                setToken('Not Available');
              })
              .catch(error => {
                // An error happened.
                alert('Logout error');
              });
          }}
        >
          Logout
        </Button>
        <Text>{`Access Token: ${token}`}</Text>
      </Stack>
    </Stack>
  );
}

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box>
        <Home />
      </Box>
    </ChakraProvider>
  );
}

export default App;
